<template>
  <div class="container mb-48">
    <div
      class="
        w-full
        h-full
        flex
        items-center
        justify-items-center justify-center
      "
    >
      <div class="w-full h-full flex flex-wrap">
        <div class="w-full lg:w-1/2 mt-12 mb-4">
          <div
            class="h-full flex items-center justify-center justify-items-center"
          >
            <div class="flex flex-wrap w-full">
              <div
                class="
                  w-full
                  mb-12
                  flex
                  items-center
                  justify-center justify-items-center
                "
              >
                <img
                  class="object-contain"
                  src="@/assets/mog/login1.svg"
                  alt=""
                />
              </div>

              <div
                class="
                  w-full
                  flex
                  items-center
                  justify-center justify-items-center
                "
              >
                <img
                  class="object-contain"
                  src="@/assets/mog/login2.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full h-full lg:w-1/2">
          <div class="title mt-12 mb-4">เปลี่ยนรหัสผ่าน</div>
         
          <!-- <label class="mb-2">เลขบัตรประชาชน</label>
          <div class="mb-1"></div>
          <input
            class="w-full mb-4"
            type="number"
            placeholder="เลขบัตรประชาชน"
            v-model="cardNumber"
            readonly
          /> -->

          <label>รหัสผ่านใหม่</label>
          <div class="mb-1"></div>
          <div class="relative flex justify-items-end justify-end mb-4">
            <span
              class="
                absolute
                inset-y-0
                flex
                items-center
                justify-items-center justify-center
                z-10
              "
              @click="checkShowHidePassword1()"
            >
              <font-awesome-icon
                class="fa-2x pr-4"
                :icon="`${iconPassword1 % 2 === 0 ? 'eye-slash' : 'eye'}`"
                color="#C6C6C6"
              />
            </span>
            <input
              id="passwordId1"
              placeholder="รหัสผ่านใหม่"
              type="password"
              v-model="password"
            />
          </div>

          <label>ยืนยันรหัสผ่านใหม่</label>
          <div class="mb-1"></div>
          <div class="relative flex justify-items-end justify-end">
            <span
              class="
                absolute
                inset-y-0
                flex
                items-center
                justify-items-center justify-center
                z-10
              "
              @click="checkShowHidePassword2()"
            >
              <font-awesome-icon
                class="fa-2x pr-4"
                :icon="`${iconPassword2 % 2 === 0 ? 'eye-slash' : 'eye'}`"
                color="#C6C6C6"
              />
            </span>
            <input
              id="passwordId2"
              placeholder="ยืนยันรหัสผ่านใหม่"
              type="password"
              v-model="confirmPassword"
            />
          </div>

          <button class="mb-4 mt-8" @click="changePassword()">ยืนยัน</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useAuth from "../hooks/useAuth";
export default {
  mounted() {
    this.getProfile();
  },
  methods: {
    getProfile() {
      useAuth
        .profile(this.token)
        .then((response) => {
          if (response.data.status === true) {
            this.cardNumber = response.data.data.cardNumber;
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // this.$swal({
            //   title: "ส่งอีเมลสำเร็จ",
            //   // icon: 'error',
            //   html: `<div align='center'><img src='${process.env.VUE_APP_URL_API}/icon/success.svg' width='100px;' height='100px;' /></div>`,
            //   confirmButtonText: "ยืนยัน",
            // });
            this.$router.push("/login");
          }
        });
    },
    changePassword() {
      useAuth
        .changePassword(
          this.token,
          this.cardNumber,
          this.password,
          this.confirmPassword
        )
        .then((response) => {
          if (response.data.status === true) {
            this.$swal({
              title: "เปลี่ยนรหัสผ่านสำเร็จ",
              icon: 'success',
              // html: `<div align='center'><img src='${process.env.VUE_APP_URL_API}/icon/success.svg' width='100px;' height='100px;' /></div>`,
              confirmButtonText: "ยืนยัน",
            });
            this.$router.push("/login");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // this.$swal({
            //   title: "ส่งอีเมลสำเร็จ",
            //   // icon: 'error',
            //   html: `<div align='center'><img src='${process.env.VUE_APP_URL_API}/icon/success.svg' width='100px;' height='100px;' /></div>`,
            //   confirmButtonText: "ยืนยัน",
            // });
            this.$router.push("/login");
          }
        });
    },
    checkShowHidePassword1() {
      this.iconPassword1 = this.iconPassword1 + 1;
      if (this.iconPassword1 % 2 === 0) {
        document.getElementById("passwordId1").type = "text";
      } else {
        document.getElementById("passwordId1").type = "password";
      }
    },
    checkShowHidePassword2() {
      this.iconPassword2 = this.iconPassword2 + 1;
      if (this.iconPassword2 % 2 === 0) {
        document.getElementById("passwordId2").type = "text";
      } else {
        document.getElementById("passwordId2").type = "password";
      }
    },
  },
  setup() {
    const cardNumber = ref(null);
    const password = ref(null);
    const confirmPassword = ref(null);
    const iconPassword1 = ref(1);
    const iconPassword2 = ref(1);
    return {
      cardNumber,
      password,
      confirmPassword,
      iconPassword1,
      iconPassword2,
    };
  },
  data() {
    return {
      token: this.$route.params.id,
    };
  },
};
</script>

<style scoped>
.image {
  width: 50px !important;
  height: 50px !important;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  /* identical to box height */
  text-align: center;
  font-weight: bold;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

.forgotPassword {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fa4238;
  text-decoration-line: underline;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

button {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  background: #573c81;
  color: white;
  border-radius: 5px;
  width: 100%;
  height: 50px;
}

.register {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #242424;
}

.container {
  font-family: KanitFont;
}
</style>